<template>
  <div>
    <!-- 头部 -->
    <div class="search">
      <el-row>
        <el-col :span="5">
          <div class="search_input">
            <el-input v-model="condition" clearable placeholder="请输入查找任务名称"></el-input>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="search_input">
            <el-button class="btn" @click="getList" type="primary">搜索</el-button>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content search_input header_list">
            <span>所有任务统计</span>
            <h2>{{ mission.missionAll }}</h2>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content search_input header_list">
            <span>有人报名任务统计</span>
            <h2>{{ mission.missionSignedUp }}</h2>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content search_input header_list">
            <span>总报名人次</span>
            <h2>{{ mission.missionObtain }}</h2>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="search_input">
            <el-button class="btn" icon="el-icon-plus" @click="handleTaskAdd" type="primary">新增</el-button>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="search_input">
            <el-button class="btn" @click="exportHandleClick" type="primary">导出列表</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <MoTable :cols="cols" :tableData="tableData" @handleEventButton="handleEventButton" :operation="operation"
      :page="page" />
    <!-- 人员弹窗  筛选条件( 未审核/已通过/驳回，姓名)  -->
    <el-dialog title="领取任务人员" :before-close="onClose" :visible.sync="outerVisible">
      <div class="search">
        <el-row>
          <el-col :span="7">
            <div class="search_input">
              <el-input v-model="person.condition" placeholder="请输入查找姓名"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="search_input">
              <el-select v-model="person.type" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="search_input">
              <el-button class="btn" @click="reset" type="primary">重置</el-button>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="search_input">
              <el-button class="btn" @click="search" type="primary">搜索</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table :data="tableDatas" border style="width: 100%">
        <el-table-column prop="name" align="center" label="昵称">
        </el-table-column>
        <el-table-column prop="phone" align="center" label="电话号码">
        </el-table-column>
        <el-table-column prop="missionStatus" align="center" :formatter="formatterStust" label="审核状态">
        </el-table-column>
        <el-table-column prop="completeTime" align="center" :formatter="formatter" label="提交状态">
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" :disabled="!scope.row.content"
              @click="handleOuter(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog width="40%" title="人员任务详情" :visible.sync="innerVisible" append-to-body>
        <el-form label-width="80px" :model="Form">
          <el-form-item label="名称">
            <el-input v-model="Form.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="电话号码">
            <el-input v-model="Form.phone" disabled></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="Form.completeTime" disabled></el-input>
          </el-form-item>
          <el-form-item label="骑手备注">
            <el-input v-model="Form.content" type="textarea" disabled rows="5" resize="none"></el-input>
          </el-form-item>
          <el-form-item label="图片" class="imgs">
            <el-image v-for="(item, index) in Form.urlBean" :key="index" style="width: 100px; height: 100px" :src="item"
              :preview-src-list="Form.urlBean">
            </el-image>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmit(1)" :disabled="Form.missionStatus == 2 ? false : true">通过</el-button>
          <el-button type="primary" @click="onSubmit(0)" :disabled="Form.missionStatus == 2 ? false : true">驳回</el-button>
          <el-button @click="innerVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <div class="pagination" v-if="person">
        <el-pagination @current-change="handleCurrentChange" :page-size="person.pageSize"
          layout="total, prev, pager, next" :total="person.total">
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 任务查看 -->
    <el-dialog width="30%" title="任务详情" :visible.sync="taskVisible" append-to-body :before-close="onCloseTask">
      <el-form label-width="100px" :model="taskForm" class="forms">
        <el-form-item label="任务名称">
          <el-input v-model="taskForm.title" disabled></el-input>
        </el-form-item>
        <el-form-item label="积分奖励">
          <el-input v-model="taskForm.integral" disabled></el-input>
        </el-form-item>
        <el-form-item label="最大人数">
          <el-input v-model="taskForm.maxNum" disabled></el-input>
        </el-form-item>
        <el-form-item label="当前人数">
          <el-input v-model="taskForm.nowNum" disabled></el-input>
        </el-form-item>
        <el-form-item label="任务类型">
          <el-select v-model="taskForm.type" disabled placeholder="请选择">
            <el-option v-for="item in taskOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务开始时间">
          <el-input v-model="taskForm.createTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="任务结束时间">
          <el-input v-model="taskForm.endTime" disabled></el-input>
        </el-form-item>
        <el-col :span="18">
          <el-form-item label="开始领取时间" prop="receiveStart">
            <el-input v-model="taskForm.receiveStart" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-row>
          <el-col :span="18">
            <el-form-item label="结束领取时间" prop="receiveEnd">
              <el-input v-model="taskForm.receiveEnd" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="任务图片" v-if="taskForm.missUrlBean">
          <template v-for="(item, index) in taskForm.missUrlBean">
            <el-image :key="index" style="width: 100px; height: 100px; margin-left: 10px" :src="item">
            </el-image>
          </template>
        </el-form-item>
        <el-form-item label="任务简介">
          <el-input v-model="taskForm.content" type="textarea" disabled rows="5" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCloseTask">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 任务发布 -->
    <el-dialog width="30%" :title="titles" :visible.sync="taskVisibleAdd" append-to-body :before-close="onCloseAdd">
      <el-form label-width="126px" ref="FormAdd" :rules="rules" :model="FormAdd" class="forms">
        <el-row>
          <el-col :span="18">
            <el-form-item label="任务名称" prop="title">
              <el-input v-model="FormAdd.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="积分奖励" prop="integral">
              <el-input v-model="FormAdd.integral"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="最大人数" prop="maxNum">
              <el-input v-model.number="FormAdd.maxNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="任务类型" prop="type">
              <el-select v-model="FormAdd.type" placeholder="请选择任务类型">
                <el-option v-for="item in taskOptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="任务开始时间" prop="createTime">
              <el-date-picker v-model="FormAdd.createTime" type="datetime" placeholder="选择开始日期时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="任务结束时间" prop="endTime">
              <el-date-picker v-model="FormAdd.endTime" type="datetime" placeholder="选择结束日期时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="开始领取时间" prop="receiveStart">
              <el-date-picker v-model="FormAdd.receiveStart" type="datetime" placeholder="选择任务开始领取时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="结束领取时间" prop="receiveEnd">
              <el-date-picker v-model="FormAdd.receiveEnd" type="datetime" placeholder="选择任务结束领取时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="任务图片">
              <el-upload action="#" ref="upload" list-type="picture-card" :on-remove="handleRemove"
                :http-request="uploadSectionFile" :file-list="fileList">
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="任务简介" prop="content">
              <el-input v-model="FormAdd.content" type="textarea" rows="5" resize="none"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitAdd('FormAdd')">发布</el-button>
        <el-button @click="onCloseAdd">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 下载 -->
    <el-dialog title="导出任务列表" :visible.sync="isExport" width="30%" :before-close="handleCloseExport">
      <el-form ref="form" :model="formExport" label-width="80px" style="text-align: left;">
        <el-form-item label="日期">
          <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始月份" end-placeholder="结束月份">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseExport">取 消</el-button>
        <el-button type="primary" @click="exportHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
export default {
  name: "lookTask",
  components: {
    MoTable,
  },
  data() {
    return {
      refForm: "FormAdd",
      // 外层弹窗
      outerVisible: false,
      //   、内层弹窗
      innerVisible: false,
      // 任务添加
      taskVisibleAdd: false,
      // 任务弹窗
      taskVisible: false,
      cols: [
        { type: "index", label: "序号" },
        { label: "任务名称", prop: "title" },
        { label: "积分奖励", prop: "integral" },
        { label: "最大人数", prop: "maxNum" },
        { label: "当前人数", prop: "nowNum" },
        {
          label: "任务类型",
          prop: "type",
          formatter: (row, column, cellValue, index) => {
            switch (row.type) {
              case 1:
                return "代办跑腿服务";
              case 2:
                return "公益宣传服务";
              case 3:
                return "环境保护服务";
              case 4:
                return "文明劝导服务";
              case 5:
                return "其他公益活动任务";

              default:
                break;
            }
          },
        },
        { label: "创建时间", prop: "createTime" },
        { label: "结束时间", prop: "endTime" },
      ],
      tableData: [],
      operation: {
        look: true,
        task: true,
        del: true,
        edit: true
      },
      tableDatas: [],
      Form: {
        completeTime: "",
        name: "",
        phone: "",
        urlBean: [],
        content: "",
      },
      // 任务详情表单
      taskForm: {},
      condition: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 人员详情
      person: {
        condition: "",
        id: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
        type: "",
      },

      // 人员状态搜索
      options: [
        { value: 0, label: "驳回" },
        { value: 1, label: "已通过" },
        { value: 2, label: "未审核" },
      ],
      // 任务类型
      taskOptions: [],
      // 人员添加表单
      FormAdd: {
        title: "",
        integral: "",
        maxNum: undefined,
        createTime: "",
        endTime: "",
        content: "",
        type: undefined,
        receiveEnd: "",
        receiveStart: "",
        missUrlBean: [],
      },
      uploadDisabled: false,
      fileList: [],
      mission: {},
      isExport: false,
      time: null,
      formExport: {
        startTime: null,
        endTime: null,
      },
      titles: ''
    };
  },
  mounted() {
    this.getList();
    this.getStatistics();
    this.getType();
  },
  methods: {
    // 导出
    exportHandleClick() {
      this.isExport = true
    },
    handleCloseExport() {
      this.isExport = false
    },
    // 导出列表
    async exportHandle() {
      if (this.time) {
        this.formExport.startTime = this.time[0]
        this.formExport.endTime = this.time[1]
      }
      let res = await this.$exl(`/mission/exportMissionList`, { ...this.formExport });
      console.log(res);
      const blob = new Blob([res], {
        type: "application/vnd.ms-excel;",
      });
      const fileName = "任务.xlsx";
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
      this.isExport = false
      this.formExport = {
        startTime: null,
        endTime: null,
        checkStatus: null
      }
    },
    // 图片上传
    async uploadSectionFile(params) {
      const fromData = new FormData();
      fromData.append("files", params.file);
      console.log(fromData);
      const res = await this.$post("/clue/upLoad", fromData);
      console.log(res);
      if (res.flag) {
        // this.uploadDisabled = true;
        this.FormAdd.missUrlBean.push(res.data[0]);
        this.fileList.push({ url: res.data[0] })
        // console.log(this.uploadDisabled);
      }
    },
    handleRemove(file, fileList) {
      // console.log(this.FormAdd.missUrlBean.indexOf(file.url) == 1);
      this.FormAdd.missUrlBean.map((item, index) => {
        if (item == file.url) {
          console.log(index);
          this.FormAdd.missUrlBean.splice(index, 1)
          this.fileList.splice(index, 1)
        }
      })
      // if(this.FormAdd.missUrlBean.indexOf(file.url))
      // this.FormAdd.missUrlBean.indexOf()
      // this.uploadDisabled = false;
      // this.$refs.upload.clearFiles();
    },
    async getType() {
      const res = await this.$get("/mission/allTypes");
      console.log(res);
      if (res.flag) {
        this.taskOptions = res.data;
      }
    },
    async getStatistics() {
      const res = await this.$get("/mission/statistics");
      console.log(res);
      if (res.flag) {
        this.mission = res.data;
      }
    },
    formatter(row) {
      if (row.content) {
        return "已提交";
      } else {
        return "未提交";
      }
    },
    formatterStust(row) {
      console.log(row);
      if (row.missionStatus == 2) {
        return "未审核";
      } else if (row.missionStatus == 1) {
        return "已通过";
      } else {
        return "驳回";
      }
    },
    handleEventButton(type, row) {
      console.log(222, row);
      if (type == "look") {
        this.outerVisible = true;
        this.person.id = row.id;
        this.getPersonList();
      } else if (type == "current") {
        this.page.pageNum = row;
        this.getList();
      } else if (type == "task") {
        this.taskVisible = true;
        this.taskForm = row;
        this.taskForm.missUrlBean.map(item => {
          this.fileList.push({ url: item })
        })
        console.log(this.fileList);
      } else if (type == "edit") {
        this.titles = '任务修改'
        this.FormAdd = Object.assign({}, this.FormAdd, row)
        this.FormAdd.missUrlBean.map(item => {
          this.fileList.push({ url: item })
        })
        this.taskVisibleAdd = true;
      } else if (type == "del") {
        this.$confirm("确认删除？")
          .then((_) => {
            this.handleDel(row);
            done();
          })
          .catch((_) => { });
      }
    },
    // 查看人员详情
    handleOuter(row) {
      console.log(row);
      this.Form = row;
      this.innerVisible = true;
    },
    // 获取任务列表
    async getList() {
      const res = await this.$get("/mission/backedGetMissionList", {
        ...this.page,
        condition: this.condition,
      });
      console.log(res);
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    onCloseTask() {
      this.taskVisible = false,
        this.fileList = []
    },
    // 任务列表搜索
    // searchLise() {
    //   this.getList()
    // },
    // 人员列表搜索
    search() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.getPersonList();
    },
    // 获取人员列表
    async getPersonList() {
      const res = await this.$get(
        "/mission/backedGetMissionPerson",
        this.person
      );
      if (res.flag) {
        this.tableDatas = res.data.rows;
        this.person.total = res.data.total;
      }
    },
    // 领取人员列表分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.person.pageNum = val;
      this.getPersonList();
    },
    async onSubmit(id) {
      this.Form.missionStatus = id;
      const res = await this.$post("/mission/checkMission", this.Form);
      console.log(res);
      if (res.flag) {
        this.innerVisible = false;
      }
    },
    reset() {
      this.person.condition = "";
      this.person.type = "";
      this.getPersonList();
    },
    onClose() {
      this.person.condition = "";
      this.person.type = "";
      this.outerVisible = false;
    },
    // 任务删除
    async handleDel(row) {
      const res = await this.$del("/mission/delMissionById?missionId=" + row.id);
      if (this.tableData.length <= 1) {
        this.page.pageNum = 1;
      }
      if (res.flag) {
        this.getList();
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    },
    // 发布任务
    handleTaskAdd() {
      this.titles = '任务发布'
      this.taskVisibleAdd = true;
    },
    // 发布任务提交
    onSubmitAdd(formName) {
      console.log(this.FormAdd);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.titles == '任务发布') {
            const res = await this.$post("/mission/publicMission", this.FormAdd);
            console.log(res);
            if (res.flag) {
              this.$refs[formName].resetFields();
              this.$message.success(res.message);
              this.taskVisibleAdd = false;
              this.getList();
              this.FormAdd = {
                title: "",
                integral: "",
                maxNum: undefined,
                createTime: "",
                endTime: "",
                content: "",
                type: undefined,
                receiveEnd: "",
                receiveStart: "",
                missUrlBean: [],
              }
              this.fileList = []
            } else {
              this.$message.error("服务器繁忙!");
            }
          } else {
            const res = await this.$put("/mission/updateMission", this.FormAdd);
            console.log(res);
            if (res.flag) {
              this.$refs[formName].resetFields();
              this.$message.success(res.message);
              this.taskVisibleAdd = false;
              this.getList();
              this.FormAdd = {
                title: "",
                integral: "",
                maxNum: undefined,
                createTime: "",
                endTime: "",
                content: "",
                type: undefined,
                receiveEnd: "",
                receiveStart: "",
                missUrlBean: [],
              }
              this.fileList = []
            } else {
              this.$message.error("服务器繁忙!");
            }
          }

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加任务关闭
    onCloseAdd() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.$refs[this.refForm].resetFields();
          this.FormAdd = {
            title: "",
            integral: "",
            maxNum: undefined,
            createTime: "",
            endTime: "",
            content: "",
            type: undefined,
            receiveEnd: "",
            receiveStart: "",
            missUrlBean: [],
          }
          this.fileList = []
          this.taskVisibleAdd = false;
          done();
        })
        .catch((_) => { });
    },
  },
  computed: {
    rules() {
      return {
        title: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
        integral: [{ required: true, message: "请输入积分", trigger: "blur" }],
        maxNum: [
          { required: true, message: "请输入最大人数", trigger: "blur" },
          { type: "number", message: "请输入数字", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择任务类型", trigger: "change" },
        ],
        createTime: [
          { required: true, message: "请选择创建时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束日期时间", trigger: "change" },
        ],
        receiveStart: [
          { required: true, message: "请选择结束日期时间", trigger: "change" },
        ],
        receiveEnd: [
          { required: true, message: "请选择结束日期时间", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入任务简介", trigger: "blur" },
        ],
        // missUrlBean: [
        //   { required: true, message: "请上传图片", trigger: "blur" },
        // ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 20px;
}

.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;

  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .btn {
      width: 110px;
      height: 40px;
    }
  }

  .search_input:nth-child(2) {
    padding: 0 !important;
  }
}

.imgs {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  /deep/ .el-form-item__content {
    margin: 0 !important;
  }

  .el-image {
    margin-right: 10px;
  }
}

.forms {
  margin-top: 10px;
}

.disabled {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}

.grid-content {
  border-radius: 4px;
}

.header_list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 14px;
    font-family: Roboto, Roboto-Regular;
    color: rgba(51, 51, 51, 0.5);
  }

  h2 {
    display: inline-block;
    font-size: 20px;
    font-family: Roboto, Roboto-Bold;
    font-weight: 700;
    color: #2196f3;
  }

  .person {
    color: #ee5252;
    font-weight: 500;
  }

  .person1 {
    color: #36d375;
    font-weight: 500;
  }

  .person2 {
    color: #ff9d43;
  }
}
</style>